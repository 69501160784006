import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./styles/About.css";


const About = () => {
  const coreValues = [
    {
      title: "Integrity",
      description:
        "At Scope Society, we uphold an unwavering commitment to truth and cohesion. Every decision, every action, is rooted in a bedrock of honesty and consistency. We strive to model this strength in integrity as we provide guidance and support to our users.",
    },
    {
      title: "Human-Centered Care",
      description:
        "We understand that behind every business lies a human dream. Prioritizing the wellbeing and success of our users and team is central to our operations. We offer a platform designed to enhance the human experience, fostering a caring environment that enables growth and success.",
    },
    {
      title: "Empathy",
      description:
        "Our passion is fueled by the aspirations of our users and their unique entrepreneurial journeys. We strive to deeply understand their needs, utilizing our platform to create meaningful, impactful experiences that resonate on a personal level.",
    },
    {
      title: "Clarity",
      description:
        "Transparency and simplicity are the pillars of our communication strategy. We cultivate an environment of unambiguous accountability, ensuring that we fulfill our mission with a lucid understanding of our shared goals and responsibilities.",
    },
    {
      title: "Passion",
      description:
        "Our passion is the lifeblood of Scope Society. It guides us through periods of transformation and fuels our desire to make a profound impact in the startup ecosystem. We channel this energy into creating solutions that empower and inspire.",
    },
    {
      title: "Inclusion",
      description:
        "Scope Society is an ecosystem where everyone belongs. We passionately uphold values of acceptance, safety, and respect, ensuring every member of our community feels valued and empowered. We believe in enhancing life through inclusive actions and creating a platform where diversity flourishes.",
    },
    {
      title: "Discipline",
      description:
        "We recognize that discipline is the backbone of sustainable growth and success. By ingraining a culture of consistent excellence, we translate disciplined actions into tangible progress, enabling our users and team to experience the benefits of meticulous planning and execution.",
    },
  ];


  return (
    <div>
    
    <div className="video-section">
      <video className="hero-video" autoPlay muted loop playsInline>
        <source src="/Images/SSAboutVid.mp4" type="video/mp4" />
        
      </video>
    </div>

  

     

      <Container>
        <Grid container spacing={4}>
          {coreValues.map((value, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div">{value.title}</Typography>
                  <Typography variant="body2">{value.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box className="about-cta">
          <Link to="/waitlist" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary">
              Join Us
            </Button>
          </Link>
        </Box>
      </Container>
    </div>
  );
};

export default About;
