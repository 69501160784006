import React, { useState } from "react";
import { Button } from "@mui/material";

import DemoBotMessages from "./utilities/demoBotMessages";

const ChatbotDemo = () => {
  const [active, setActive] = useState(false);

  const handleButtonClick = () => {
    setActive(!active);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        {active ? "Stop Interaction" : "Sample AI Interaction"}
      </Button>
      {active && <DemoBotMessages />}
    </div>
  );
};

export default ChatbotDemo;
