import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDdD_Rajp1eCIpC0Mxl0637mO4nZjTGflQ",
  authDomain: "scope-society.firebaseapp.com",
  projectId: "scope-society",
  storageBucket: "scope-society.appspot.com",
  messagingSenderId: "975068077851",
  appId: "1:975068077851:web:ab351a0bd723db18f73dbe",
  measurementId: "G-RZ22PVNSVJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
