import React from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import GroupIcon from "@mui/icons-material/Group";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import PanoramaIcon from "@mui/icons-material/Panorama";

import BookIcon from "@mui/icons-material/Book";
import "./styles/Features.css"; // import your CSS file

const Features = () => {
  const featuresData = [
    {
      title: "Personalized Guidance",
      description:
        "Our AI-driven platform delivers tailored advice that adapts to the unique needs and contexts of your startup, enhancing your decision-making process.",
      icon: <EmojiObjectsIcon />,
    },
    {
      title: "Effective Networking",
      description:
        "Foster meaningful connections and partnerships within the startup ecosystem, opening doors to collaborative opportunities.",
      icon: <GroupIcon />,
    },
    {
      title: "Wellness Support",
      description:
        "Beyond business advice, our platform recognizes the mental and emotional challenges of entrepreneurship, offering resources and support for holistic wellness.",
      icon: <SelfImprovementIcon />,
    },
    {
      title: "Virtual Reality Simulations",
      description:
        "Harness the power of VR to gain practical experience and improve skills crucial to startup success.",
      icon: <PanoramaIcon />,
    },
    {
      title: "In-depth Learning",
      description:
        "Access a vast repository of knowledge and insights from successful founders, amplifying your learning curve.",
      icon: <BookIcon />,
    },
  ];

  return (
    <Box className="features-container">
      <Typography
        variant="h4"
        className="features-title"
        textAlign="center"
        gutterBottom
      >
        Empowering Startups with AI-Driven Mentorship
      </Typography>
      <Grid container spacing={4} justifyContent="center" mt={5}>
        {featuresData.map((feature) => (
          <Grid item xs={12} sm={6} md={4} key={feature.title}>
            <Card className="features-card">
              <CardContent className="features-card-content">
                <Box className="features-icon-box">{feature.icon}</Box>
                <Typography
                  variant="h6"
                  className="features-title"
                  gutterBottom
                >
                  {feature.title}
                </Typography>
                <Typography variant="body1" className="features-description">
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
