import React, { useState } from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link as RouterLink } from "react-router-dom";
import "./styles/HeroSection.css";

const HeroSection = () => {
  const [expandedStep, setExpandedStep] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);

  const steps = [
    {
      title: "User Onboarding and Needs Assessment",
      details: "Register, fill in your information, and complete our detailed pre-survey questionnaire tailored to understand your specific startup needs.",
    },
    {
      title: "Idea Exploration and Hypothesis Creation",
      details: "Explore business ideas with AI-facilitated creativity, test hypotheses using simulations, and create a personalized market research template.",
    },
    {
      title: "Education, Mentorship, and Networking",
      details: "Attend expert seminars, receive personalized mentorship, engage in community forums, and benefit from continuous support and adaptation as your business grows.",
    },
  ];

  const sections = [
    {
      title: "Scope Society Core Program",
      content: "Our AI-driven Core Program empowers entrepreneurs through personalized incubation journeys. From ideation to growth, transform your vision into a thriving business with Scope Society.",
    },
    {
      title: "Founder Lab Pathway",
      content: "Access virtual advisory programs, discover product-market fit, and gain insights from top-tier advisors. The Founder Lab is your gateway to startup success.",
    },
  ];

  const toggleContent = (index) => {
    if (expandedSection === index) {
      setExpandedSection(null);
    } else {
      setExpandedSection(index);
    }
  };

  return (
    <div>
      <div className="hero-wrapper">
        <div className="hero">
          <div className="waitlist-button-container">
            <Button
              component={RouterLink}
              to="/waitlist"
              className="waitlist-button"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Join the Waitlist Today
            </Button>
          </div>
          <video
            src="/Images/scopeVid.mp4"
            autoPlay
            loop
            muted
            playsInline
            poster="/Images/SSVid.png"
          />
        </div>
        <div className="hero-image-container">
  <img src="/Images/scopeVid.png" alt="Hero section img" />
</div>

      </div>
  
      <section className="content">
        <h1 className="AI-powered"> AI-Powered Incubator for Startups</h1>
        <div className="card">
          <p>
            Join Scope Society and make years of progress in just a few months. Experience an AI-driven journey tailored to your startup's unique needs. Don't follow the trends, set them!
          </p>
        </div>
        {sections.map((section, index) => (
          <div className="section" key={index}>
            <h2 onClick={() => toggleContent(index)}>{section.title}</h2>
            <div className="content" style={{ display: expandedSection === index ? "block" : "none" }}>
              {section.content}
            </div>
          </div>
        ))}
        <h2 className="how-it-works-title"><span>How It Works</span></h2>
        <ol className="how-it-works">
          {steps.map((step, index) => (
            <li key={index}>
              <div className={`flip-card-inner ${expandedStep === index ? "is-flipped" : ""}`}
                   onClick={() => setExpandedStep(expandedStep === index ? null : index)}>
                <div className="flip-card-front">
                  <ArrowForwardIcon className="icon" />
                  <span>{step.title}</span>
                </div>
                <div className="flip-card-back">
                  <div className="step-details">{step.details}</div>
                </div>
              </div>
            </li>
          ))}
        </ol>
        <Button
          component={RouterLink}
          to="/about"
          variant="outlined"
          color="primary"
          style={{ marginTop: "1em" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Learn More
        </Button>
      </section>
    </div>
  );
  
};

export default HeroSection;
