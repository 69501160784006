// src/theme/customTheme.js
import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#8d264f", // Primary color
    },
    secondary: {
      main: "#ca9bae", // Secondary color
    },
    warning: {
      main: "#f1d850", // Warning color
    },
    info: {
      main: "#ab5f7d", // Info color
    },
    background: {
      default: "#ffffff", // Background color
    },
    // Add more customizations if needed
  },
  typography: {
    // Customize the typography if needed
  },
});

export default customTheme;
