// src/components/Header.js

import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuOpen(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <img src="/Images/logo.png" alt="Company Logo" height="50px" />
        </Link>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Scope Society
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={mobileMenuOpen}
              open={Boolean(mobileMenuOpen)}
              onClose={handleMobileMenuClose}
              keepMounted
            >
              {/* Add links for each page in your application */}
              <Link
                to="/product"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>Product</MenuItem>
              </Link>
              <Link
                to="/features"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>Features</MenuItem>
              </Link>
             {/*  <Link
                to="/mentors"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>Mentors</MenuItem>
              </Link>*/}
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>About Us</MenuItem>
              </Link>
              
            <Link
                to="/mentors"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>Mentors</MenuItem>
              </Link>
            {/*  <Link
                to="/resources"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>Resources</MenuItem>
              </Link>*/} 
              <Link
                to="/waitlist"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem onClick={handleMobileMenuClose}>
                  Join Waitlist
                </MenuItem>
              </Link>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Add buttons for each page in your application */}
            <Link
              to="/product"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Product</Button>
            </Link>
            <Link
              to="/features"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Features</Button>
            </Link>
            
            <Link
              to="/mentors"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Mentors</Button>
            </Link>
           {/* <Link
              to="/mentors"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Mentors</Button>
            </Link>*/}
            <Link
              to="/about"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">About Us</Button>
            </Link>
        {/* <Link
              to="/resources"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Resources</Button>
            </Link>*/}
            <Link
              to="/waitlist"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">Join Waitlist</Button>
            </Link>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
