import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import { Instagram, LinkedIn } from "@mui/icons-material";
import "./styles/Footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <div className="logo row">
            <div className="footer-header">
              <img src="/image.png" className="slogo" alt="" />
            </div>
            <div className="logo-des">
              <RouterLink
                to="/about"
                className="btn-know"
                onClick={scrollToTop}
              >
                Learn More
              </RouterLink>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="contact">
            <div className="footer-header">
              <h3>Contact</h3>
            </div>
            <div className="contact-des">
              <p>
                Start your journey with Scope Society, an AI-powered incubator
                dedicated to providing personalized guidance and support for
                startups.
              </p>

              <Typography variant="body2" component="p">
                info@scopesociety.com
              </Typography>

              <Typography variant="body2">+1</Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="link row">
            <div className="footer-header">
              <h3>Links</h3>
            </div>

            <div className="link-des">
              <RouterLink
                to="/"
                color="inherit"
                className="footer-link"
                onClick={scrollToTop}
              >
                Home
              </RouterLink>
              <RouterLink
                to="/about"
                color="inherit"
                className="footer-link"
                onClick={scrollToTop}
              >
                About
              </RouterLink>
              <RouterLink
                to="/features"
                color="inherit"
                className="footer-link"
                onClick={scrollToTop}
              >
                Features
              </RouterLink>
              <RouterLink
                to="/product"
                color="inherit"
                className="footer-link"
                onClick={scrollToTop}
              >
                Product
              </RouterLink>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <div className="newsletter row">
            <div className="footer-header">
              <h3>Newsletter</h3>
            </div>
            <div className="newsletter-des">
              <div className="subscribe">
                <i className="sub-icon ri-mail-check-fill"></i>

                <IconButton color="primary">
                  <i className="sub-icon ri-arrow-right-line"></i>
                </IconButton>
              </div>
              <div className="icons">
                <IconButton
                  color="primary"
                  component="a"
                  href="https://www.instagram.com/scopesociety_projects/"
                  target="_blank"
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  color="primary"
                  component="a"
                  href="https://www.linkedin.com/company/scope-society/"
                  target="_blank"
                >
                  <LinkedIn />
                </IconButton>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="footer-bottom">
        <hr />

        <Button
          component={RouterLink}
          to="/waitlist"
          variant="contained"
          color="primary"
          style={{ marginBottom: "1em" }} // Add some margin to give it some space
          onClick={scrollToTop}
        >
          Join Our Waitlist
        </Button>

        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()} Scope Society. All rights reserved.
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
