import React from "react";
import { Card, Typography, Grid } from "@mui/material";
import "./styles/Metrics.css";

const metrics = [
  {
    title: "Access to Bigger Market",
    value: "5X",
    description:
      "Reach 5 times larger audience compared to traditional methods.",
  },
  {
    title: "Customer Validation",
    value: "80%",
    description:
      "80% of users reported an improved understanding of their target customers.",
  },
  {
    title: "Product Market Fit",
    value: "2X",
    description: "Double the success rate in achieving product market fit.",
  },
  {
    title: "Business Insights",
    value: "4x",
    description:
      "Leverage AI to amplify your decision-making power by four times, aiding in understanding exactly what needs to be done and in prioritizing effectively.",
  },
];

const Metrics = () => {
  return (
    <Grid container spacing={2} className="metrics-container">
      {metrics.map((metric, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card className="metric-card">
            <Typography variant="h5" className="metric-title">
              {metric.title}
            </Typography>
            <Typography variant="h2" className="metric-value">
              {metric.value}
            </Typography>
            <Typography className="metric-description">
              {metric.description}
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Metrics;
