import React from "react";
import { Typography, Grid } from "@mui/material";
import ChatbotDemo from "./ChatbotDemo";
import Dashboard from "./Dashboard";
import Metrics from "./Metrics";
import "./styles/Product.css";

const ProductPage = () => {
  return (
    <div className="ProductPageContainer">
      <Typography variant="h2" component="h1" gutterBottom className="AI-powered">
        Scope Society: AI-Powered Incubator
      </Typography>

      <Metrics />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <div className="Section">
            <Typography variant="h4" gutterBottom className="gradient-text">
              Key Features
            </Typography>
            <ul>
              <li>
                AI-Driven Mentoring: Personalized guidance from AI-powered mentors
              </li>
              <li>
                Virtual Accelerator Programs: Real-world business simulations and case studies
              </li>
              <li>
                Community Networking: Connect with fellow entrepreneurs and industry experts
              </li>
              <li>
                Investor Pitch Preparation: Fine-tune your pitch and get ready for the investment community
              </li>
            </ul>
            <div className="ImageContainer">
              <img src="/Images/ScopeSocietyDashboard.png" alt="Scope Society Dashboard" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="Section">
            <Dashboard />
            <Typography variant="h4" gutterBottom className="gradient-text">
              Why Choose Scope Society?
            </Typography>

            <Typography variant="body1">
              Scope Society leverages advanced AI technologies to create a virtual incubator experience tailored to modern entrepreneurs. We simulate realistic business scenarios, offer AI-powered mentorship, and provide a thriving community of like-minded innovators. With Scope Society, you gain the insights, feedback, and support you need to bring your ideas to life. Join us and embark on your entrepreneurial journey with confidence.
            </Typography>
          </div>
        </Grid>
      </Grid>

      <ChatbotDemo />
    </div>
  );
};

export default ProductPage;
