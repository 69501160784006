import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import customTheme from "./theme/customTheme";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import Features from "./components/Features";
import Footer from "./components/Footer";
import WaitlistPage from "./components/WaitlistPage";
import Product from "./components/Product";

import MentorList from "./components/MentorList"; 
// import ResourcePage from "./components/ResourcePage";
// import Mentors from "./components/Mentors"; 

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Features />} />
          <Route path="/product" element={<Product />} />
          <Route path="/mentors" element={<MentorList />} />
          <Route path="/waitlist" element={<WaitlistPage />} />
        
          {/*<Route path="/resources" element={<ResourcePage />} /> */}
          {/*<Route path="/mentors" element={<Mentors />} />  */}
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
