import React from "react";
import WaitlistForm from "./WaitlistForm";
import { Container, Typography } from "@mui/material";
import "./styles/WaitlistPage.css"; // Add this line

const WaitlistPage = () => {
  return (
    <div className="waitlist-page">
      <Container maxWidth="sm">
        <WaitlistForm />
      </Container>
    </div>
  );
};

export default WaitlistPage;
