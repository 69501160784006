import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import "../components/styles/MentorList.css";


const MentorList = () => {
  const [mentors, setMentors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", company: "" });
  const [userSubmitted, setUserSubmitted] = useState(localStorage.getItem("userSubmitted") === "true");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/mentors");
        setMentors(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showDialog && !userSubmitted) {
        setShowDialog(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showDialog, userSubmitted]);

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/user-access/save-user-access", formData);
  
      if (response.status === 200) {
        console.log("User data saved successfully:", formData);
        localStorage.setItem("userSubmitted", "true");
        setUserSubmitted(true);
        setShowDialog(false);
      } else if (response.status === 400) {
        console.log("User already exists:", formData);
      } else {
        console.error("Failed to save user data:", response);
      }
    } catch (error) {
      console.error("An error occurred while saving user data:", error);
    }
  };
  

  return (
    <Container className="container">
      <Typography variant="h1">Mentor List</Typography>
      {isLoading ? (
        <Typography variant="body1">Loading...</Typography>
      ) : (
        <Grid container spacing={2}>
        {Array.isArray(mentors) && mentors.map((mentor, index) => (

            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className="list-item">
                <CardContent>
                  <Typography variant="h6">{`Name: ${mentor.Name}`}</Typography>
                  <Typography variant="body2">{`Company: ${mentor["Company Type"]}`}</Typography>
                  <Typography variant="body2">{`Functional Area: ${mentor["Functional Area"]}`}</Typography>
                  <Typography variant="body2">{`Title: ${mentor.Title}`}</Typography>
                  <Typography variant="body2">{`Areas: ${mentor["Most relevant areas they can be helpful"]}`}</Typography>
                  <Typography variant="body2">
                    {"Link: "}
                    {mentor["Link to connect"].includes("calendly") ? "N/A" : <a href={mentor["Link to connect"]} target="_blank" rel="noreferrer">Click Here</a>}
                  </Typography>
                </CardContent>
                <Divider />
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={showDialog} onClose={handleClose}>
        <DialogTitle>Enter your details to continue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your name, email, and company to continue viewing the mentor list.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="company"
            label="Company"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MentorList;
