import React, { useState } from "react";
import { TextField, Button, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import axios from "axios";
import "./styles/WaitlistForm.css";

const WaitlistForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!regex.test(email)) {
      setError(true);
      setErrorMessage("Invalid email address");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/waitlist`,
        {
          firstName,
          lastName,
          email,
        }
      );

      if (response.status === 201) {
        setSuccess(true);
        setFirstName(""); // Clear firstName input after successful submit
        setLastName(""); // Clear lastName input after successful submit
        setEmail(""); // Clear email input after successful submit
      }
    } catch (err) {
      setError(true);
      setErrorMessage(
        err.response
          ? err.response.data
          : "Something went wrong. Please try again later."
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
    setSuccess(false);
  };

  return (
    <div className="waitlist-form">
      <h1>JOIN OUR WAITLIST</h1>
      <p>Sign up now and be the first to know when we launch!</p>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          placeholder="Enter your first name..."
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          error={error}
          required
          style={{ marginRight: "1em" }}
        />
        <TextField
          variant="outlined"
          placeholder="Enter your last name..."
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          error={error}
          required
          style={{ marginRight: "1em" }}
        />
        <TextField
          variant="outlined"
          placeholder="Enter your email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={error}
          helperText={errorMessage}
          required
          style={{ marginRight: "1em" }}
        />
        <Button variant="contained" color="primary" type="submit">
          Join Waitlist
        </Button>
      </form>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          Successfully joined the waitlist!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WaitlistForm;
