import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const messages = [
  { sender: "User", content: "Hi, I'm Alex, and I'm working on a startup idea related to sustainable packaging solutions. I'm at the idea stage and need help validating my concept and understanding the market. Can you help?" },
  { sender: "AI Copilot", content: "Hi Alex! Welcome to Scope Society. I'm here to assist you with your sustainable packaging startup. Let's start by validating your idea. Can you tell me more about your target audience and the problem you're trying to solve?" },
  { sender: "User", content: "Sure, my target audience is small to medium-sized e-commerce businesses. The problem I'm addressing is excessive waste in packaging. I want to create biodegradable packaging solutions that are both cost-effective and environmentally friendly." },
  { sender: "AI Copilot", content: "That's a great initiative, Alex! Based on your target audience and problem statement, I can generate a custom market research template for you. This template will help you conduct customer validation by understanding the needs and preferences of e-commerce businesses in your target market. Would you like me to do that?" },
  { sender: "User", content: "Yes, please! That would be very helpful." },
  { sender: "AI Copilot", content: "Great! I've created the market research template, and you can download it here. Additionally, we have an upcoming seminar on sustainable practices in e-commerce. Would you be interested in attending?" },
  { sender: "User", content: "Absolutely, that sounds very relevant. Sign me up!" },
  { sender: "AI Copilot", content: "You're all set for the seminar! Now, let's talk about building your idea further. Have you considered working with a mentor? We can match you with experienced mentors in the sustainability and e-commerce fields." },
  { sender: "User", content: "That would be excellent. I think a mentor could really help guide me." },
  { sender: "AI Copilot", content: "Wonderful! I'll initiate the mentor-matching process. In the meantime, you may also want to explore our Growth Track tailored to early-stage startups like yours. It includes specialized sprints and milestones to guide you through validation, team building, and go-to-market strategies. Can I guide you through that process?" },
  { sender: "User", content: "Yes, please. I want to make sure I'm taking the right steps to grow my startup successfully." },
  { sender: "AI Copilot", content: "Perfect, Alex! Let's start with the Launch Track, where you'll focus on validation, team building, and planning your go-to-market strategy. Here's a [link to the Launch Track](#), and I'm always here to assist you along the way." },
  { sender: "User", content: "Thank you so much! I feel more confident already." },
  { sender: "AI Copilot", content: "You're welcome, Alex! Remember, you're not just another startup; you're the next big thing. I'm here whenever you need support. Good luck with your sustainable packaging venture!" }
];


// Define your styles
const Message = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const UserMessage = styled(Message)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  alignSelf: "flex-start",
}));

const AIMessage = styled(Message)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.text.primary,
  alignSelf: "flex-end",
}));

const ChatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 400,
  overflowY: "auto",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

// Set delay function
const delay = (interval) =>
  new Promise((resolve) => setTimeout(resolve, interval));

const DemoBotMessages = () => {
  const [displayedMessages, setDisplayedMessages] = useState([]);

  useEffect(() => {
    (async () => {
      for (const message of messages) {
        await delay(2000);

        setDisplayedMessages((oldMessages) => [...oldMessages, message]);
      }
    })();
  }, []);

  return (
    <ChatBox>
      {displayedMessages.map((message, index) => {
        return message.sender === "User" ? (
          <UserMessage key={index}>
            <Typography variant="subtitle1">
              <strong>{message.sender}:</strong> {message.content}
            </Typography>
          </UserMessage>
        ) : (
          <AIMessage key={index}>
            <Typography variant="subtitle1">
              <strong>{message.sender}:</strong> {message.content}
            </Typography>
          </AIMessage>
        );
      })}
    </ChatBox>
  );
};

export default DemoBotMessages;