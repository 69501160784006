import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  FunnelChart,
  Funnel,
  Cell,
} from "recharts";
import "./styles/Dashboard.css";

// Brand color kit
const COLORS = ["#ca9bae", "#8d264f", "#ab5f7d", "#ffffff"];

// Modified data to better represent your business context
const sentimentData = [
  { name: "Positive", value: 70 },
  { name: "Negative", value: 20 },
  { name: "Neutral", value: 10 },
];

const marketData = [
  { name: "Jan", uv: 3000, pv: 2000, amt: 2500 },
  { name: "Feb", uv: 3500, pv: 2200, amt: 2600 },
  { name: "Mar", uv: 4000, pv: 2400, amt: 2800 },
  { name: "Apr", uv: 4200, pv: 2500, amt: 3000 },
  { name: "May", uv: 4500, pv: 2800, amt: 3200 },
  { name: "Jun", uv: 4700, pv: 3000, amt: 3400 },
  { name: "Jul", uv: 5000, pv: 3500, amt: 3800 },
];

const funnelData = [
  { value: 1000, name: "Visitors" },
  { value: 800, name: "Sign-ups" },
  { value: 500, name: "Trial Users" },
  { value: 300, name: "Paying Customers" },
];

// Component for the Dashboard
const Dashboard = () => {
  // State for window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width upon window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Adjust the chart width and height based on the window width
  const chartWidth = windowWidth < 600 ? windowWidth * 0.9 : 400;
  const chartHeight = windowWidth < 600 ? windowWidth * 0.45 : 400;

  return (
    <div className="dashboard-container">
      <div className="chart-section">
        <h2 className="chart-title">User Sentiment Analysis</h2>
        <PieChart width={chartWidth} height={chartHeight} className="chart">
          <Pie
            data={sentimentData}
            cx={chartWidth / 2}
            cy={chartHeight / 2}
            labelLine={false}
            outerRadius={Math.min(chartWidth, chartHeight) * 0.4}
            fill="#8884d8"
            dataKey="value"
            label
          >
            {sentimentData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      <div className="chart-section">
        <h2 className="chart-title">Market Dynamics</h2>
        <LineChart
          width={chartWidth}
          height={chartHeight}
          data={marketData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          className="chart"
        >
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="pv"
            stroke={COLORS[0]}
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="uv" stroke={COLORS[1]} />
        </LineChart>
      </div>

      <div className="chart-section">
        <h2 className="chart-title">Customer Interest Validation</h2>
        <FunnelChart width={chartWidth} height={chartHeight} className="chart">
          <Tooltip />
          <Funnel dataKey="value" data={funnelData} isAnimationActive>
            <Cell
              nameKey="name"
              fill={COLORS[2]}
              stroke="#fff"
              strokeWidth={1}
            />
          </Funnel>
        </FunnelChart>
      </div>
    </div>
  );
};

export default Dashboard;
